import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { MainLayout } from "@/layouts/MainLayout";
import Helmet from "react-helmet";
import { SEO } from "@/components/basic/SEO";
import { emailRegExp } from "@/utils/validation";
import { Dropdown } from "@/components/basic/Dropdown";
import { TileCheckboxGroup } from "@/components/TileCheckboxGroup";
import { Input } from "@/components/basic/Input";
import { ValidatedInput } from "@/components/basic/ValidatedInput";
import { PreviousPageInput } from "@/components/basic/PreviousPageInput";
import { Textarea } from "@/components/basic/Textarea";
import { MailingListCheckbox } from "@/components/MailingListCheckbox";
import { SuccessPageContent } from "@/components/SuccessPageContent";
import { FailurePageContent } from "@/components/FailurePageContent";
import { FormSubmitButton } from "@/components/basic/FormSubmitButton";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import { PrivacyPolicyInfo } from "@/components/PrivacyPolicyInfo";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormHandler } from "@/hooks/useFormHandler";
import {
  developmentStageOptions,
  budgetOptions,
  whatToDoOptions,
  projectTypeOptions,
} from "@/utils/estimateProjectData";
import {
  requiredFieldMessage as required,
  invalidFormatMessage as invalid,
} from "@/utils/validationMessages";

const ProjectEstimate: FC<PageProps<GatsbyTypes.Query>> = ({ data }) => {
  const {
    state,
    errors,
    isValid,
    isRecaptcha,
    register,
    handleCaptchaChange,
    handleFormSubmit,
    handleMailingListChange,
  } = useFormHandler(process.env.FORMCARRY_ESTIMATE_PROJECT_FORM_ID);

  return (
    <MainLayout>
      <Helmet>
        <body className="ltr project-estimate" id="intro" />
      </Helmet>
      {/*<SEO*/}
      {/*  title={metaTitle}*/}
      {/*  description={metaDescription.metaDescription}*/}
      {/*  keywords={metaKeywords}*/}
      {/*/>*/}
      {state.submitted && (
        <SuccessPageContent
          heading="Thank you for considering ANIXE for your project!"
          text="Your request has been submitted. We’ll get back to you soon to give
          you details on your project pricing"
        />
      )}
      {state.error && (
        <FailurePageContent goBackUrl="/estimate-project" goBackText="return" />
      )}
      {!state.submitted && !state.error && (
        <section className="overlay overlay-static">
          <div className="l-wrapper l-with-dbl-vertical-gutters-mobile l-dbl-push-top">
            <div className="l-10-cols-tablet l-8-cols-desktop l-island default-form color-bg-negative l-with-dbl-gutters-mobile">
              <div className="l-full">
                <h2 className="color-primary">Estimate project</h2>
                <p className="text-bold l-push-bottom">
                  Looking for bespoke, cutting-edge software development?
                </p>
                <p className="l-push-bottom">
                  We create software tailored to the customer’s expectations
                  regardless of the industry and location. We love challenges.
                </p>
                <p className="l-push-bottom">
                  Please complete the form to help us understand your vision and
                  accurately cater to your needs. We will contact you ASAP to
                  show you an initial estimate and discuss the next steps.
                </p>
                <p className="l-push-bottom">
                  <strong className="color-error">*</strong>
                  required fields
                </p>
              </div>
              <div className="l-push-bottom l-bleed">
                <form onSubmit={handleFormSubmit}>
                  <div className="l-dbl-push-bottom clearfix">
                    <TileCheckboxGroup
                      heading="What do you like to do?"
                      register={register}
                      options={whatToDoOptions}
                      errorMessage={errors.whatToDo && required}
                    />
                  </div>
                  <div className="l-dbl-push-bottom clearfix">
                    <TileCheckboxGroup
                      heading="What is the type of your project?"
                      register={register}
                      options={projectTypeOptions}
                      errorMessage={errors.projectType && required}
                    />
                  </div>
                  <div className="l-push-bottom clearfix">
                    <div className="l-half-tablet">
                      <ValidatedInput
                        name="name"
                        label="Name"
                        register={register}
                        errorMessage={errors.name && required}
                      />
                    </div>
                    <div className="l-half-tablet">
                      <ValidatedInput
                        name="companyName"
                        label="Company name"
                        register={register}
                        errorMessage={errors.companyName && required}
                      />
                    </div>
                  </div>
                  <div className="l-push-bottom clearfix">
                    <div className="l-half-tablet">
                      <ValidatedInput
                        name="email"
                        type="email"
                        label="Email address"
                        register={register}
                        pattern={emailRegExp}
                        errorMessage={
                          errors.email &&
                          (errors.email.type === "pattern" ? invalid : required)
                        }
                      />
                    </div>
                    <div className="l-half-tablet">
                      <Input name="companyWebsite" label="Company website" />
                    </div>
                  </div>
                  <div className="l-push-bottom clearfix">
                    <div className="l-half-tablet">
                      <Input name="country" label="Country" />
                    </div>
                    <div className="l-half-tablet flex-checkbox">
                      <Input
                        name="NDA"
                        type="checkbox"
                        label="I need an NDA"
                        value="on"
                      />
                    </div>
                  </div>
                  <div className="l-push-bottom clearfix">
                    <div className="l-half-tablet">
                      <Dropdown
                        label="What is your development stage?"
                        name="developmentStage"
                        placeholder="Select stage"
                        options={developmentStageOptions}
                      />
                    </div>
                    <div className="l-half-tablet">
                      <Dropdown
                        label="What is your budget?"
                        name="budget"
                        placeholder="Select budget range"
                        options={budgetOptions}
                      />
                    </div>
                  </div>
                  <div className="l-full-mobile l-push-bottom">
                    <Textarea
                      label="How can we help you?"
                      placeholder="Enter your idea"
                      name="idea"
                      register={register}
                      errorMessage={errors.idea && required}
                    />
                  </div>
                  <PrivacyPolicyInfo />
                  <div className="l-full-mobile l-dbl-push-bottom text-12">
                    <MailingListCheckbox
                      handleChange={handleMailingListChange}
                    />
                  </div>
                  <PreviousPageInput />
                  <div className="align-center clearfix">
                    <div className="l-half-tablet">
                      <ReCAPTCHA
                        sitekey={process.env.FORMCARRY_SITEKEY}
                        onChange={handleCaptchaChange}
                      />
                    </div>
                    <FormSubmitButton
                      text="estimate"
                      disabled={!isRecaptcha || !isValid}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      <SiteFooterWrapper>
        <SiteFooterNewsletterButton />
      </SiteFooterWrapper>
    </MainLayout>
  );
};

export default ProjectEstimate;
// export const query = graphql`
//   {
//     contentfulProjectEstimatePage {
//       metaTitle
//       metaDescription {
//         metaDescription
//       }
//       metaKeywords
//     }
//   }
// `;
